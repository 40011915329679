import { initSentry } from './initSentry';
import replaceTrackingPixelUrls from './replaceTrackingPixelUrls';
import { fire_braze_conversion, get_is_braze, init } from './utils';

declare global {
  interface Window {
    trackingPixelUrls?: string[];
  }
}

initSentry();

//Init:
init();

const track_conversion = () => {
  get_is_braze() && void fire_braze_conversion();
};

$('#jpform1').on('lmssubmit:always', track_conversion);

window.trackingPixelUrls = replaceTrackingPixelUrls(window.trackingPixelUrls);
