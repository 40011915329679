import { get_is_braze, get_lead_id, get_reauid, get_adid } from './utils';

const addParam = (key: string, value: string | null): string =>
  value ? `${key}=${value}/` : '';

const addMidReauidAdidToTrackingUrls = (
  trackingPixelUrls: string[],
  mid: string | null,
  reauid: string | null,
  adid: string | null,
): string[] => {
  const newTrackingUrl = `rea/count/${addParam('mid', mid)}${addParam(
    'reauid',
    reauid,
  )}${addParam('adid', adid)}advid=`;

  return trackingPixelUrls.map((trackingPixelUrl) =>
    trackingPixelUrl.replace('rea/count/advid=', newTrackingUrl),
  );
};

const replaceTrackingPixelUrls = (
  trackingPixelUrls: string[] | undefined,
): string[] | undefined => {
  if (!trackingPixelUrls) {
    return;
  }

  if (get_is_braze()) {
    return [];
  }

  const mid = get_lead_id();
  const reauid = get_reauid();
  const adid = get_adid();

  if (!mid && !reauid && !adid) {
    return trackingPixelUrls;
  }

  return addMidReauidAdidToTrackingUrls(trackingPixelUrls, mid, reauid, adid);
};

export default replaceTrackingPixelUrls;
